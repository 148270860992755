<template>
  <v-card class="mt-3 pb-5" :color="ant">
    <v-card-text class="mt-2">

      <div class="d-flex">
        <span class="text-h5">Contract total: $ {{ order.amount === null ? '0.00' : order.amount }}</span>
        <v-spacer></v-spacer>
       <v-btn outlined class="" :color="essayGreen" :loading="loading1" @click="showPayment = true" v-if="getSession.user_type === 1"  >+ Add funds</v-btn>
      </div>

      <v-progress-linear
        :value="order.percentage_released"
        class="mt-4 rounded-lg"
        :color="essayGreen"
        striped
        height="20"
      ></v-progress-linear>
    </v-card-text>
    <v-card-actions class="ml-3"> 
      <span v-if ="getSession.user_type === 1 && getSession.customer.balance < order.amount" >
        Released ${{ order.amount_released ? order.amount_released : '0.00' }}({{ order.percentage_released ? order.percentage_released : '0.00' }}%)
      </span>
      <span v-else>
        Paid ${{order.amount_released}} ({{ order.percentage_released }} %)
      </span>
      <v-spacer></v-spacer>
      <span v-if="getSession.user_type === 2" >
        My share ${{ order.writer_amount }} (80%)
      </span>
    </v-card-actions>
    <v-card-actions class="ml-2" v-if="getSession.user_type === 1 && !order.accept">
      <v-btn :color="primaryBlue" class="pa-5" @click="release=true"> Release Payment</v-btn>
      <v-spacer></v-spacer>
      <v-btn :color="essayGreen" class="pa-5" @click="endContract" :loading="false" > End contract</v-btn>
    </v-card-actions>
    <ReleaseModal :show="release" @close="release= false" :order="order" />
    <PaymentModal 
      :show="showPayment" 
      description="Deposit from Bank card" 
      @close="showPayment=false"
      :minimumAmount="insufficientBalance"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "contract",
  props: ['order'],
  components: {
    PaymentModal: () => import('@/modules/PaymentModal'),
    ReleaseModal: () => import('@/modules/writersDashboard/orderDetail/_components/contractComponent/releaseModule')
  },
  mixins: [colorMxn],
  data() {
    return {
      release: false,
      loading: false,
      showPayment: false,
      loading1: false
    };
  },
  computed: {
    ...mapGetters(['getSession']),
    insufficientBalance() {
      const result = this.getSession.user__type === 1 ? this.order.amount - this.getSession.customer.balance : 0;
      return result <= 0 ? '0.00' : result;
    }
  },
  methods: {
    ...mapMutations(['setReload']),
    ...mapActions(['performPutActions']),
    async endContract() {
      this.loading = true;
      
      const payload = {
        accept: 1,
        amount_released: this.order.amount,
        percentage_released: 100,
      }

      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      
      await this.performPutActions(fullPayload);
      this.setReload(true);
      this.loading = false;
    },
  }
};
</script>
